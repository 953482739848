import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NavigationEnd, Router, Event } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AppService } from 'src/app/app.global';
@Injectable({
  providedIn: 'root'
})
export class LivelocationService {

  API_PATH: string = environment.apiPath;
  public httpOption: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    observe: 'response'
  };

  constructor(
    private http: HttpClient,
    public router: Router,
    private websocket: WebSocket,
    public appservice:AppService
  ) { }

  getlocationList(search:any,filter:any,hr_or_team:any,from_date:any,to_date:any){    
    let url = "hr-location-list"
    if(hr_or_team == 'team')
      url = "team-location-list"
    
    if(filter != ''){
      return this.http.get(`${this.API_PATH}geolocation/api/v2/${url}/?hr_or_team=${hr_or_team}&from_date=${from_date}&to_date=${to_date}&search=${search}&${filter}&ordering=name`, this.httpOption)
    }else if (search != ''){
      return this.http.get(`${this.API_PATH}geolocation/api/v2/${url}/?hr_or_team=${hr_or_team}&from_date=${from_date}&to_date=${to_date}&search=${search}&ordering=name`, this.httpOption)
    } else {
      return this.http.get(`${this.API_PATH}geolocation/api/v2/${url}/?hr_or_team=${hr_or_team}&from_date=${from_date}&to_date=${to_date}`, this.httpOption)
    }
    
  }
  getOneemployee(id:any,date:any="",from:any=""){
    let url ="hr-employee-breadcrumb-data"
    if(from == 'team')
      url = "team-employee-breadcrumb-data"
      return this.http.get(`${this.API_PATH}geolocation/api/v2/${url}/?employee=${id}&date=${date}`, this.httpOption)
  }
  
  getgpslocationList(hr_or_team:any){
    if(hr_or_team == "team")
      return this.http.get(`${this.API_PATH}geolocation/api/v2/team-location-gps-list/?hr_or_team=${hr_or_team}&ordering=name`, this.httpOption)
    else
      return this.http.get(`${this.API_PATH}geolocation/api/v2/hr-location-gps-list/?hr_or_team=${hr_or_team}&ordering=name`, this.httpOption)
  }
  getgpsLostCnt(hr_or_team:any){
    if(hr_or_team == "team")
      return this.http.get(`${this.API_PATH}geolocation/api/v2/team-gps-lost-count`, this.httpOption)
    else
      return this.http.get(`${this.API_PATH}geolocation/api/v2/hr-gps-lost-count`, this.httpOption)

  }
  notifyemp(id:any,frompage:any){
    if(frompage == 'hr')
       return this.http.get(`${this.API_PATH}geolocation/api/v1/hr-notify-employee/${id}/`, this.httpOption)
    else
      return this.http.get(`${this.API_PATH}geolocation/api/v1/team-notify-employee/${id}/`, this.httpOption)
  }
  employeeListData(status:any,filter:any,hr_or_team:any){
    if(filter != ''){
      return this.http.get(`${this.API_PATH}geolocation/api/v1/live-location-dropdown-list/?is_active=${status}&hr_or_team=${hr_or_team}&ordering=name&${filter}`, this.httpOption)
    }else{
      return this.http.get(`${this.API_PATH}geolocation/api/v1/live-location-dropdown-list/?is_active=${status}&hr_or_team=${hr_or_team}&ordering=name`, this.httpOption)
    }
  }

  getMylocationList(from_date:any,to_date:any){
    return this.http.get(`${this.API_PATH}geolocation/api/v2/profile-location-list/?from_date=${from_date}&to_date=${to_date}`, this.httpOption)
  }
  mybreadcrumbData(date:any="",id:any){
      return this.http.get(`${this.API_PATH}geolocation/api/v2/profile-breadcrumb-data/?employee=${id}&date=${date}`, this.httpOption)
  }


  empList:any
  WEBSOCKET_URL: string =environment.webSocketEndPoint;
  loc_item: EventEmitter<any> = new EventEmitter();
  public liveLocationSocket(){

      this.websocket = new WebSocket(`${this.WEBSOCKET_URL}real-time-location-tracking/${this.appservice.getSubDomain()}_realtimelocationtracking/`);
      this.websocket.onopen = (event:any) => {
        // console.log('Opened location socket: ', event);
      }

      this.websocket.onmessage = (event:any) => {

        let data = JSON.parse(event.data);
        this.loc_item.emit(data);
      }

      this.websocket.onclose = (event:any) => {

      }
      this.router.events.subscribe((event: Event) => {
        if (event instanceof NavigationEnd) {
            // Hide loading indicator
            if(event.url !== '/employee-live-location'){
             this.closeSocket();
            }
        }
      });


      if (this.websocket.readyState === WebSocket.OPEN) {

      }

    }

    public closeSocket(){
      if (this.websocket.readyState === WebSocket.OPEN) {
        // console.log("location close")
        this.websocket.close();

        this.websocket.addEventListener('close', (event) => {

        });
      }
  }

}

